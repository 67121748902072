import React, { Component } from 'react';
import Socket from '../core/socket';
import Foto from './foto';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
            first: true,
            src: ''
        }
    }

    componentDidMount() {
        Socket.on('test', data => {
            alert(JSON.stringify(data));
        });

        this.verifyMobile();
    }

    verifyMobile() {
        if (
            (navigator.userAgent.match(/Android/i)) ||
            (navigator.userAgent.match(/webOS/i)) ||
            (navigator.userAgent.match(/iPhone/i)) ||
            (navigator.userAgent.match(/iPod/i)) ||
            (navigator.userAgent.match(/iPad/i)) ||
            (navigator.userAgent.match(/BlackBerry/i))
        ) {
            this.setState({isMobile: true});
        } else {
            this.setState({isMobile: false});
        }
    }

    handleChange (event) {
        this.setState({ first: false });

        var file = event.target.files[0];

        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ src: reader.result })
            );

            reader.readAsDataURL(file);
        }

        //this.props.history.push(`/foto/${file}`);
        //upload(file);
        //this.drawOnCanvas(file);   // see Example 6
        //displayAsImage(file); // see Example 7   
    };

    drawOnCanvas(file) {
        var reader = new FileReader();
        reader.onload = function (e) {
            var dataURL = e.target.result,
                c = document.querySelector('canvas'), // see Example 4
                ctx = c.getContext('2d'),
                img = new Image();
        
            img.onload = function() {
                c.width = img.width;
                c.height = img.height;
                ctx.drawImage(img, 0, 0);
            };
        
            img.src = dataURL;
        };
    
        reader.readAsDataURL(file);
    }

    changeScreen(st) {
        console.log(st);
        this.setState({ first: st });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.first ?
                    <div id="home" className="column justify-center align-center">  
                        <div className="container column justify-center aling-center">
                            <div className="white-space-32"></div>
                            <div className="titulo align-center justify-center">
                                <h1>Camera Test</h1>
                            </div>  
                            <div className="white-space-32"></div>
                        </div>
                        <div className="white-space-16"></div>
                        <div className="container2 column justify-center align-center">
                            <div className="white-space-32"></div>
                            <div className="titulo align-center justify-center">
                                <h2>Tomar una foto</h2>
                            </div>  
                            <div className="white-space-32"></div>
                            <div className="row justify-center align-center">
                                <input type="file" id="mypic" accept="image/*" capture="camera" onChange={this.handleChange.bind(this)}/>
                                {this.state.isMobile?
                                    <label id="fileBtn" htmlFor="mypic">
                                        <i className="fas fa-camera"></i>
                                    </label>
                                    : 
                                    <label id="fileBtn">No Disponible</label>
                                }
                            </div>
                            {/*<div className="white-space-16"></div>
                            <canvas className="canvas-img"></canvas>*/}
                        </div>
                    </div> :    
                    <Foto               
                        changeScreen={this.changeScreen.bind(this)} 
                        src={this.state.src}
                    />}   
            </React.Fragment>
        )
    }
}

export default Home;