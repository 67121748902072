import React, {Component} from 'react';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

class Foto extends Component{
    constructor(props) {
        super(props);
        this.state = {
            crop: {
                unit: "%",
                width: 30,
                aspect: 16 / 9
            }
        }
    }

    // If you setState the crop in here you should return false.
    onImageLoaded = (image) => {
        this.imageRef = image;
    };
    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
        const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            "newFile.jpeg"
        );
        this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
        );

        return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
            }
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve(this.fileUrl);
        }, "image/jpeg");
        });
    }

    render(){
        let src = this.props.src ? this.props.src : '';
        console.log(src);
        const { crop, croppedImageUrl } = this.state;

        return(
            <div id="foto" className="foto column aling-center justify-center">
                <div className="container justify-center align-center">
                    <div className="column justify-center align-center">
                    <div className="imagen-original justify-center align-center">
                        {src && (
                            <ReactCrop
                                src={src}
                                crop={crop}
                                ruleOfThirds
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                            />
                            )}
                            {/*<canvas className="canvas-img" ></canvas>*/}
                        </div>
                        <div className="white-space-32"></div>
                        {croppedImageUrl && (
                            <img alt="Crop" style={{ maxWidth: "100%" }} src={croppedImageUrl} />)}
                    </div>    
                </div>
                <div className="white-space-32"></div>
                <div className="container2 align-center justify-center">
                    <label id="fileBtn" htmlFor="mypic">
                        <a onClick={this.props.changeScreen.bind(this, true)}>Cancelar</a>     
                    </label>  
                </div>    
            </div>  
            
        )
    }
}

export default Foto;