import React, { Component } from 'react';
import { BrowserRouter, Route } from "react-router-dom";

// Pages
import Home from '../pages/home';
import Foto from '../pages/foto';

class Routes extends Component {
    render() {
        return (
            <div>
                <BrowserRouter>
                    <Route path="/" exact component={Home}></Route>
                    <Route path="/foto/:img" exact component={Foto}></Route>
                </BrowserRouter>
            </div>
        )
    }
}

export default Routes;